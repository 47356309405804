import axios from '@/libs/axios'
import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'

export default {
  namespaced: true,
  state: {
    dataSourcesCarousel: [],
    dataAdmissionForms: [],
  },
  getters: {
    dataSourcesCarousel: state => state.dataSourcesCarousel,
    dataAdmissionForms: state => state.dataAdmissionForms,
  },
  mutations: {
    SET_DATA_SOURCES: (state, data) => { state.dataSourcesCarousel = data },
    SET_DATA_ADMISSION_FORMS: (state, data) => { state.dataAdmissionForms = data },
  },
  actions: {
    async getDataSources({ commit }, params) {
      try {
        const response = await axios.get('/admissions/admission_carousels', { params })
        const { data } = response
        if ( data.code === ApiCode.SUCCESS ) {
          const {
            total,
            records
          } = data.data
          commit('SET_DATA_SOURCES', records)
        }
      } catch ( e ) {
        handleCatch(e)
      }
    },
    async readAdmissionFormByAdmissionRoundId({ commit }, params) {
      try {
        const response = await axios.get('/admissions/admission_form/getAdmissionFormByAdmissionRoundId', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const {
            total,
            records
          } = data.data
          commit('SET_DATA_ADMISSION_FORMS', records)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
  },
}
