import axios from '@/libs/axios'
import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'

export default {
  namespaced: true,
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
    async create({ commit }, params) {
      try {
        const response = await axios.post('/admissions/admission_language_certificates', params)
        const { data } = response
        const isSuccessful = data.code === ApiCode.SUCCESS
        if (isSuccessful) {
          return { isSuccessful, message: data.message }
        }
        return { isSuccessful, message: data.debugMessage }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async update({ commit }, params) {
      try {
        const response = await axios.put(`/admissions/admission_language_certificates/${params.id}`, params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async uploadApplicantStudentLanguageCertificate({ commit }, { id, params }) {
      try {
        const response = await axios.put(`/admissions/admission_language_certificates/${id}/upload`, params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, data: data.data }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async delete({ commit }, id) {
      try {
        const response = await axios.delete(`/admissions/admission_language_certificates/${id}`)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
  },
}
