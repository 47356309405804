import axios from '@/libs/axios'
import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'

export default {
  namespaced: true,
  state: {
    dataListConfirmationType: [],
    dataListPriorityZones: [],
    admissionConducts: [],
    academicPerformances: [],
    dataListCertificateType: [],
    dataListSubject: [],
    dataListLanguageCertificateType: [],
    dataListEthnics: [],
    dataListReligions: [],
    dataListGenders: [],
    dataListProvince: [],
    dataListDistrict: [],
    dataListWard: [],
    dataListHighSchool: [],
    dataApplicantStudentDetail: [],
    dataListAdmissionForm: [],
    dataListMajorByAdmissionForm: [],
    dataListAdmissionBlockByAdmissionMajorForm: [],
    codeAdmissionForm: [],
  },
  getters: {
    dataListConfirmationType: state => state.dataListConfirmationType,
    dataListPriorityZones: state => state.dataListPriorityZones,
    admissionConducts: state => state.admissionConducts,
    academicPerformances: state => state.academicPerformances,
    dataListCertificateType: state => state.dataListCertificateType,
    dataListSubject: state => state.dataListSubject,
    dataListLanguageCertificateType: state => state.dataListLanguageCertificateType,
    dataListEthnics: state => state.dataListEthnics,
    dataListReligions: state => state.dataListReligions,
    dataListGenders: state => state.dataListGenders,
    dataListProvince: state => state.dataListProvince,
    dataListDistrict: state => state.dataListDistrict,
    dataListWard: state => state.dataListWard,
    dataListHighSchool: state => state.dataListHighSchool,
    dataApplicantStudentDetail: state => state.dataApplicantStudentDetail,
    dataListAdmissionForm: state => state.dataListAdmissionForm,
    dataListMajorByAdmissionForm: state => state.dataListMajorByAdmissionForm,
    dataListAdmissionBlockByAdmissionMajorForm: state => state.dataListAdmissionBlockByAdmissionMajorForm,
    codeAdmissionForm: state => state.codeAdmissionForm,
  },
  mutations: {
    SET_DATA_LIST_CONFIRMATION_TYPE: (state, data) => { state.dataListConfirmationType = data },
    SET_DATA_LIST_PRIORITY_ZONE: (state, data) => { state.dataListPriorityZones = data },
    SET_ACADEMIC_PERFORMANCES: (state, data) => { state.academicPerformances = data },
    SET_ADMISSION_CONDUCTS: (state, data) => { state.admissionConducts = data },
    SET_CERTIFICATE_TYPE: (state, data) => { state.dataListCertificateType = data },
    SET_DATA_LIST_SUBJECT: (state, data) => { state.dataListSubject = data },
    SET_DATA_LIST_LANGUAGE_CERTIFICATE: (state, data) => { state.dataListLanguageCertificateType = data },
    SET_DATA_LIST_ETHNICS: (state, data) => { state.dataListEthnics = data },
    SET_DATA_LIST_RELIGIONS: (state, data) => { state.dataListReligions = data },
    SET_DATA_LIST_GENDERS: (state, data) => { state.dataListGenders = data },
    SET_DATA_LIST_PROVINCES: (state, data) => { state.dataListProvince = data },
    SET_DATA_LIST_DISTRICTS: (state, data) => { state.dataListDistrict = data },
    SET_DATA_LIST_WARDS: (state, data) => { state.dataListWard = data },
    SET_DATA_LIST_HIGH_SCHOOLS: (state, data) => { state.dataListHighSchool = data },
    SET_DATA_APPLICANT_STUDENT_DETAIL: (state, data) => { state.dataApplicantStudentDetail = data },
    SET_DATA_LIST_ADMISSION_FORM: (state, data) => { state.dataListAdmissionForm = data },
    SET_MAJOR_BY_ADMISSION_FORM: (state, data) => { state.dataListMajorByAdmissionForm = data },
    SET_ADMISSION_BLOCK_BY_ADMISSION_MAJOR_FORM_ID: (state, data) => { state.dataListAdmissionBlockByAdmissionMajorForm = data },
    SET_CODE_BY_ADMISSION_ID: (state, data) => { state.codeAdmissionForm = data },
  },
  actions: {
    async getCodeByAdmissionForm({ commit }, params) {
      try {
        const response = await axios.get('admissions/admission_form/getAdmissionFormById', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_CODE_BY_ADMISSION_ID', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async getListConfirmationType({ commit }, params) {
      try {
        const response = await axios.get('uni/factAdmissionConfirmationTypes/allActive', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const result = data.data.map(item => ({
            value: item.id,
            label: item.name,
          }))
          commit('SET_DATA_LIST_CONFIRMATION_TYPE', result)
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async getListPriorityZones({ commit }) {
      try {
        const response = await axios.get('uni/factPriorityZones/getAll')
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const result = data.data.map(item => ({
            value: item.id,
            label: item.name,
          }))
          commit('SET_DATA_LIST_PRIORITY_ZONE', result)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getAcademicPerformances({ commit }) {
      try {
        const response = await axios.get('uni/factAcademicPerformances/getAll')
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const result = data.data.map(item => ({
            value: item.id,
            label: item.name,
          }))
          commit('SET_ACADEMIC_PERFORMANCES', result)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getAdmissionConducts({ commit }) {
      try {
        const response = await axios.get('uni/factAdmissionConducts/getAll')
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const result = data.data.map(item => ({
            value: item.id,
            label: item.name,
          }))
          commit('SET_ADMISSION_CONDUCTS', result)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getCertificateType({ commit }, params) {
      try {
        const response = await axios.get('uni/factAdmissionCertificateTypes/allActive', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const result = data.data.map(item => ({
            value: item.id,
            label: item.name,
          }))
          commit('SET_CERTIFICATE_TYPE', result)
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async getListSubject({ commit }, params) {
      try {
        const response = await axios.get('uni/factAdmissionSubjects/allActive', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const result = data.data.map(item => ({
            value: item.id,
            label: item.name,
          }))
          commit('SET_DATA_LIST_SUBJECT', result)
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async getLanguageCertificateType({ commit }, params) {
      try {
        const response = await axios.get('uni/factAdmissionLanguageCertificateTypes/allActive', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const result = data.data.map(item => ({
            value: item.id,
            label: item.name,
          }))
          commit('SET_DATA_LIST_LANGUAGE_CERTIFICATE', result)
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async getDataListEthnics({ commit }, params) {
      try {
        const response = await axios.get('uni/factEthnics/allActive', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const result = data.data.map(item => ({
            value: item.id,
            label: item.name,
          }))
          commit('SET_DATA_LIST_ETHNICS', result)
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async getDataListReligions({ commit }, params) {
      try {
        const response = await axios.get('uni/factReligions/allActive', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const result = data.data.map(item => ({
            value: item.id,
            label: item.name,
          }))
          commit('SET_DATA_LIST_RELIGIONS', result)
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async getDataListGenders({ commit }, params) {
      try {
        const response = await axios.get('uni/factGenders/allActive', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const result = data.data.map(item => ({
            value: item.id,
            text: item.name,
          }))
          commit('SET_DATA_LIST_GENDERS', result)
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async getDataListProvinces({ commit }, params) {
      try {
        const response = await axios.get('uni/factProvinces/allActive', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const result = data.data.map(item => ({
            value: item.code,
            label: item.name,
          }))
          commit('SET_DATA_LIST_PROVINCES', result)
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async getDataListDistricts({ commit }, params) {
      try {
        const response = await axios.get('uni/fact_districts/allActive', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const result = data.data.map(item => ({
            value: item.code,
            label: item.name,
          }))
          commit('SET_DATA_LIST_DISTRICTS', result)
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async getDataListWards({ commit }, params) {
      try {
        const response = await axios.get('uni/fact_ward/allActive', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const result = data.data.map(item => ({
            value: item.code,
            label: item.name,
          }))
          commit('SET_DATA_LIST_WARDS', result)
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async getDataListHighSchools({ commit }, params) {
      try {
        const response = await axios.get('uni/factHighSchools/allActive', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const result = data.data.map(item => ({
            value: item.id,
            label: item.name,
          }))
          commit('SET_DATA_LIST_HIGH_SCHOOLS', result)
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async getDataHighSchoolById({ commit }, params) {
      try {
        const response = await axios.get('uni/factHighSchools/getById', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          return data.data
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async uploadApplicantStudentAvatar({ commit }, { id, params }) {
      try {
        const response = await axios.put(`admissions/applicant_students/${id}/uploadAvatar`, params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, data: data.data }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async uploadIdentificationFront({ commit }, { id, params }) {
      try {
        const response = await axios.put(`admissions/applicant_students/${id}/identificationFront`, params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, data: data.data }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async uploadIdentificationBack({ commit }, { id, params }) {
      try {
        const response = await axios.put(`admissions/applicant_students/${id}/identificationBack`, params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, data: data.data }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async getLinkAvatar({ commit }, link) {
      try {
        const response = await axios.get(`admissions/applicant_students/getFileMinioByPathFile?pathFile=${link}`)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, data: data.data }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async updateApplicantStudent({ commit }, params) {
      try {
        const response = await axios.put(`/admissions/applicant_students/${params.id}`, params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async getApplicantStudentDetail({ commit }, params) {
      try {
        const response = await axios.get(`admissions/applicant_students/${params.id}`)
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_DATA_APPLICANT_STUDENT_DETAIL', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getDataListAdmissionForm({ commit }, params) {
      try {
        const response = await axios.get('/admissions/admission_form/getAdmissionFormByAdmissionRoundId', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const result = data.data.records.map(item => ({
            value: item.id,
            label: item.name,
          }))
          commit('SET_DATA_LIST_ADMISSION_FORM', result)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getMajorByAdmissionFormId({ commit }, params) {
      try {
        const response = await axios.get('/admissions/admission_major_form/getByAdmissionFormId', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const result = data.data.map(item => ({
            value: item.majorId,
            label: item.majorName,
          }))
          commit('SET_MAJOR_BY_ADMISSION_FORM', result)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getAdmissionBlockByAdmissionMajorFormId({ commit }, params) {
      try {
        const response = await axios.get('/admissions/admission_major_form/getByAdmissionMajorFormId', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const result = data.data.map(item => ({
            value: item.admissionBlockId,
            label: item.admissionBlockName,
          }))
          commit('SET_ADMISSION_BLOCK_BY_ADMISSION_MAJOR_FORM_ID', result)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
  },
}
