import axios from '@/libs/axios'
import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'

export default {
  namespaced: true,
  state: {
    trainingSystems: [],
    checkRegister: true,
  },
  getters: {
    trainingSystems: state => state.trainingSystems,
    checkRegister: state => state.checkRegister,
  },
  mutations: {
    SET_TRAINING_SYSTEMS: (state, records) => {
      state.trainingSystems = records
    },
    SET_CHECK_REGISTER: (state, checkRegister) => {
      state.checkRegister = checkRegister
    },
  },
  actions: {
    async checkDateAdmissionRound({ commit }, id) {
      try {
        const response = await axios.get(`/admissions/admission_round/checkDateRegister/${id}`)
        const { data } = response
        commit('SET_CHECK_REGISTER', data.data)
      } catch (e) {
        handleCatch(e)
      }
    },
    async getTrainingSystems({ commit }, parameters) {
      try {
        const ownParams = { organizationId: null, trainingFormId: '', sort: '1_asc' }
        const params = {
          ...ownParams,
          ...parameters,
        }
        const response = await axios.get('uni/trainingSystems', { params })
        
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { records } = data.data
          const mapData = mapDropdown(records)
          commit('SET_TRAINING_SYSTEMS', mapData)
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async createAccount({ commit }, params) {
      try {
        const response = await axios.post('admissions/applicant_students/register', params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async resetPassword({ commit }, params) {
      try {
        const response = await axios.put(`/accounts/${params.id}/resetPassword`, params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },

    async changePassword({ commit }, params) {
      try {
        const response = await axios.put(`/accounts/${params.id}/changePassword`, params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
  },
}
