import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'
import axios from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    navMenuItems: [],
  },
  getters: {
    navMenuItems: state => state.navMenuItems,
  },
  mutations: {
    SET_NAV_MENU_ITEMS: (state, menus) => { state.navMenuItems = menus },
  },
  actions: {
    async getNavMenuItems({ commit }) {
      try {
        const response = await axios.get('auth/menus/admissions')
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          const { menus } = data.data
          commit('SET_NAV_MENU_ITEMS', menus)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
  },
}
