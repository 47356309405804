import { ResourceCode } from '@/const/code'

export default [
  {
    path: '/',
    name: 'admissionDashboard',
    component: () => import('@/views/dashboard/Dashboard.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Trang chủ',
          active: true,
        },
      ],
      action: 'READ',
      resource: ResourceCode.ADMISSION_DASHBOARD,
    },
  },
  {
    path: '/admissionInfo',
    name: 'admissionInfo',
    component: () => import('@/views/admission-info/AdmissionInfo.vue'),
    meta: {
      breadcrumb: [
        {
          text: 'Trang chủ',
          active: true,
        },
        {
          text: 'Tin tức tuyển sinh',
          active: false,
        },
      ],
      action: 'READ',
      resource: ResourceCode.ADMISSION_INFO,
    },
  },
  {
    path: '/news/:newsId',
    name: 'newsDetail',
    component: () => import('@/views/admission-info/NewsDetail.vue'),
    meta: {
      resource: ResourceCode.ADMISSION_INFO,
    },
  },
]
