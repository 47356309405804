import { handleCatch } from '@/utils'
import { ApiCode } from '@/const/api'
import axios from '@/libs/axios'

export default {
  namespaced: true,
  state: {
    examinationRooms: [],
  },
  getters: {
    examinationRooms: state => state.examinationRooms,
  },
  mutations: {
    SET_EXAMINATION_ROOMS: (state, examinationRooms) => { state.examinationRooms = examinationRooms },
  },
  actions: {
    async getListExamination({ commit }, params) {
      try {
        const response = await axios.get('admissions/applicantStudentExaminationRooms/getListExamination', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_EXAMINATION_ROOMS', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },

    async handleReviewScore({ commit }, params) {
      try {
        const response = await axios.post('admissions/applicantStudentExaminationRooms/handleReviewScore', params)
        const { data } = response
        return { isSuccessful: data.code === ApiCode.SUCCESS, message: data.message }
      } catch (e) {
        handleCatch(e)
      }
    },

    async createApplicantCheckExamination({ commit }, body) {
      try {
        const response = await axios.post('admissions/applicant_check_examination', body)
        const { data } = response
        return {
          isSuccessful: data.code === ApiCode.SUCCESS,
          message: data.message,
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    async getReportCheckExam({ commit }, params) {
      try {
        const response = await axios.get('/admissions/applicantStudentExaminationRooms/get_report_payment', { params })
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          return data.data
        }
      } catch (e) {
        handleCatch(e)
      }
    },
  },
}
