import Vue from 'vue'
import VueRouter from 'vue-router'
import { getLoginType, isUserLoggedIn } from '@/auth/utils'
import { canNavigate } from '@/libs/acl/routeProtection'
// eslint-disable-next-line import/no-cycle
import store from '@/store'
import ability from '@/libs/acl/ability'
import { LOGIN_TYPE } from '@/const/type'
import dashboard from '@/router/dashboard/dashboard'
import auth from './routes/auth'
import admission from '@/router/admission/admission'
import examination from '@/router/examination/examination'
import admissionScore from '@/router/admission-score/admissionScore'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    ...auth,
    ...dashboard,
    ...admission,
    ...examination,
    ...admissionScore,
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach(async (to, _, next) => {
  const isLoggedIn = isUserLoggedIn()
  if (!canNavigate(to)) {
    if (!isLoggedIn) return next({ name: 'login' })
    return next({ name: 'notAuthorized' })
  }
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    next({ name: 'portalDashboard' })
  }
  if (isLoggedIn) {
    let user = null
    const loginType = getLoginType()
    if (loginType === LOGIN_TYPE.NORMAL) {
      user = await store.dispatch('auth/verify')
    } else if (loginType === LOGIN_TYPE.SSO) {
      user = await store.dispatch('auth/verifySso')
    }
    if (user) {
      ability.update(user.abilities)
    }
  }
  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
