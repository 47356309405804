import { ApiCode } from '@/const/api'
import { handleCatch } from '@/utils'
import axios from '@axios'

export default {
  namespaced: true,
  state: {
    payment: {},
    paymentByUrl: {},
    paymentTransaction: {},
    queryPaymentStatus: {},
  },
  getters: {
    payment: state => state.payment,
    paymentByUrl: state => state.paymentByUrl,
    paymentTransaction: state => state.paymentTransaction,
    queryPaymentStatus: state => state.queryPaymentStatus,
  },
  mutations: {
    SET_PAYMENT: (state, data) => { state.payment = data },
    SET_PAYMENT_BY_URL: (state, records) => { state.paymentByUrl = records },
    SET_QUERY_PAYMENT_STATUS: (state, data) => { state.queryPaymentStatus = data },
  },

  actions: {
    async getPayment({ commit }, id) {
      try {
        const response = await axios.get(`admissions/payments/${id}`)
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_PAYMENT', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async queryPayment({ commit }, id) {
      try {
        const response = await axios.get(`admissions/payments/query-transaction/${id}`)
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_QUERY_PAYMENT_STATUS', data.data)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getPaymentByReturnUrl({ commit }, params) {
      try {
        const response = await axios.post('admissions/payments/verify-payment', params)
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_PAYMENT_BY_URL', data.data.records[0] || null)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    async getPaymentByTransactionId({ commit }, id) {
      try {
        const response = await axios.get(`admissions/payments/transaction/${id}`)
        const { data } = response
        if (data.code === ApiCode.SUCCESS) {
          commit('SET_PAYMENT', data.data.records[0] || null)
        }
      } catch (e) {
        handleCatch(e)
      }
    },
    // eslint-disable-next-line no-unused-vars
    async initPayment({ commit }, params) {
      try {
        const response = await axios.post('admissions/payments/init/', params)
        const { data } = response
        return {
          isSuccessful: data.code === ApiCode.SUCCESS,
          message: data.message,
          url: data.data,
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
    // eslint-disable-next-line no-unused-vars
    async updatePayment({ commit }, params) {
      try {
        const response = await axios.put('admissions/payments/', params)
        const { data } = response
        return {
          isSuccessful: data.code === ApiCode.SUCCESS,
          message: data.message,
        }
      } catch (e) {
        handleCatch(e)
      }
      return null
    },
  },
}
